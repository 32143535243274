import React from 'react';
import './Buton.css';
import { Link as Anchor } from 'react-router-dom';

export default function Buton() {
    return (
        <div className='Buton'>
            <Anchor to={`https://antoniolabrunaweb.com.ar/account/login `} className='logo'>
                ¡Conoce nuestro nuevo E-commerce!
            </Anchor>
        </div>
    );
}
