import React from 'react'
import './Nosotros.css'
import img from '../../images/about.png'
export default function Nosotros() {
    return (
        <div className='Nosotros'>
            <img src={img} alt="imagen" />
            <div className='NosotrosText'>
                <h2>Quienes somos</h2>
                <p>Somos una empresa argentina, dedicada a la representación de marcas líderes para la distribución en el mercado nacional e internacional. Somos especialistas en la comercialización e importación de productos de una amplia variedad rubros y marcas nacionales e internacionales.</p>
                <p>Contamos con una importante infraestructura, compuesta por oficinas y depósitos de más de 10.000 [m2] cubiertos, en condiciones de brindar un servicio de excelente calidad. A principios de 2013 nos trasladamos a un nuevo Centro de Distribución con más de 5.000 [m2] de depósito y una totalidad de 7.000 [m2] de superficie. Con una cartera de más de 5.000 empresas minoristas y clientes activos, logrando poner al alcance de la gente los más de 16.000 artículos que componen nuestro variado stock. La capacitación permanente y el excelente clima laboral que ANTONIO F. LA BRUNA S.R.L. brinda a sus colaboradores, logra que las más de 100 personas que componen nuestro equipo tome como propia la pasión y vocación de servicio sumando la experiencia que sólo empresas líderes pueden transmitir.</p>
            </div>
        </div>
    )
}
