import React, { useEffect, useState, useRef } from 'react';
import './Galery.css'
import Galery1 from '../../images/1.jpg';
import Galery2 from '../../images/2.jpg';
import Galery3 from '../../images/3.jpg';
import Galery4 from '../../images/24.jpg';
import Galery5 from '../../images/5.png';
import Galery6 from '../../images/6.png';
import Galery7 from '../../images/7.png';
import Galery8 from '../../images/8.png';
import Galery9 from '../../images/9.png';
import Galery10 from '../../images/10.png';
import Galery11 from '../../images/11.png';
import Galery12 from '../../images/12.png';
import Galery13 from '../../images/13.jpg';
import Galery14 from '../../images/14.jpg';
import Galery15 from '../../images/15.jpg';
import Galery16 from '../../images/16.jpg';
import Galery17 from '../../images/17.jpg';
import Galery18 from '../../images/18.jpg';
import Galery19 from '../../images/19.jpg';
import Galery20 from '../../images/20.jpg';
import Galery21 from '../../images/21.jpg';
import Galery22 from '../../images/22.jpg';
import Galery23 from '../../images/23.jpg';
import Galery24 from '../../images/24.jpg';
import Galery25 from '../../images/25.jpg';

export default function Galery() {

    const images = [
        Galery2, Galery8, Galery18, Galery19, Galery6, Galery1, Galery3, Galery4, Galery5,
        Galery21, Galery10,
        Galery11, Galery12, Galery13, , Galery15,
        Galery17, Galery20, Galery21, Galery22, Galery23, Galery24, Galery25
    ];

    return (
        <div className='galeryContain'>
            {images.map((image, index) => (
                <div id='SwiperSlide-Galery' key={index} onClick={() => { }}>
                    <img src={image} alt={`katana-${index}`} />
                </div>
            ))}
        </div>
    );
}
