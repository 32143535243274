import React, { useState, useEffect } from 'react';
import './Numeros.css';

export default function Numeros() {
    const numeros = [
        {
            numero: 5337,
            titulo: 'Clientes'
        },
        {
            numero: 242,
            titulo: 'Marcas'
        },
        {
            numero: 21823,
            titulo: 'Artículos'
        }
    ];

    const [displayedNumbers, setDisplayedNumbers] = useState([]);

    useEffect(() => {
        const interval = setInterval(() => {
            setDisplayedNumbers((prevNumbers) => {
                const updatedNumbers = prevNumbers.map((num, index) => {
                    if (num < numeros[index].numero) {
                        return num + 1;
                    } else {
                        return num;
                    }
                });
                return updatedNumbers;
            });
        }, 10); // Velocidad de la presentación (en milisegundos)
        return () => clearInterval(interval);
    }, []);

    // Inicializar los números mostrados al montar el componente
    useEffect(() => {
        setDisplayedNumbers(numeros.map(() => 0));
    }, []);

    return (
        <div className='Numeros'>
            <div className='NumerosCards'>
                {numeros.map((item, index) => (
                    <div className='NumeroCard' key={index}>
                        <h2>{displayedNumbers[index]}</h2>
                        <p>{item.titulo}</p>

                    </div>
                ))}
            </div>
        </div>
    );
}
