import IndexLayout from "../Layouts/IndexLayout";
import { createBrowserRouter } from "react-router-dom";
export const router = createBrowserRouter([

    {
        path: "/",
        element: <IndexLayout />,

    },


]);
