import React from 'react'
import './Direccion.css'
import img from '../../images/direccion.png'
export default function Direccion() {
    return (
        <div className='Direccion'>
            <img src={img} alt="imagen" />

        </div>
    )
}
