import React from 'react'
import './Hero.css'
import img from '../../images/hero.png'
export default function Hero() {
    return (
        <div className='HeroContain'>

            <img src={img} alt="" />
        </div>
    )
}
